#tsparticles {
    position: absolute;
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    top: 0;
    left: 0;
}

.fireworks-particle-canvas {
    width: 100%;
    height: 100%;
    position: unset !important;
    z-index: -1;
    top: 0px;
    left: 0px;
    background-color: rgb(0, 0, 0);
    pointer-events: initial;
}
