@import '../../assets/css/variables.module.scss';
.BottomNavContainer {
  margin-top: 56px;
  position: relative;
  color: "red";
  .BottomNav {
    position: fixed;
    bottom: 0;
    top: auto;
    width: 100%;
    background-color: $bottom-nav-bg;
    border-top: 1px solid gray;
    z-index: 1111;
    height: 56px;
    display: flex;
    justify-content: space-evenly;

  }

  .BottomItem{
    color: $lite;
  }
}
