iframe.threeDs {
  width: 370px;
  height: 366px;
  margin: 100px 0 0 -175px;
  position: fixed;
  top: 0;
  left: 50%;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.6);
  z-index: 9991;
}

